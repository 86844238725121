import styled from 'styled-components';

export const Container = styled.div`
  a {
    width: 100%;
    color: inherit;
    text-decoration: none;
  }

  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Body = styled.section`
  width: 100%;
  background-color: transparent;

  .categorySelect {
    h1 {
      text-align: center;
      font-size: larger;
      color: #4a4a4a;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .select {
      width: 50%;
      margin: 0 auto;
      outline: none;
      margin-bottom: 30px;
    }
  }

  .gridContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 auto;

    .toolbar {
      display: flex;
      width: 99%;
      float: right;
      gap: 15px;
      justify-content: flex-end;
    }

    .grid {
      min-width: 100%;
      .dx-header-row {
        background-color: #efefef;
        border: 0;

        .dx-datagrid-text-content {
          font-size: 14px;
          color: black;
          font-weight: bold;
        }
      }

      .dx-datagrid-action {
        color: #bbbbbb;
        font-weight: bold;
        text-transform: uppercase;
      }

      .dx-data-row {
        color: #333333;
        font-weight: 700;
      }

      .dx-datagrid-addrow-button {
        background-color: #8b0304;
        color: white;
      }

      .dx-datagrid-addrow-button.dx-state-hover {
        background-color: #650001;
      }

      .dx-icon-edit-button-addrow {
        color: white !important;
      }
    }
  }
`;

export const ContainerLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #efefef;
  height: 100vh;
`;

export const ToolItem = styled.div`
  padding: 3px 9px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.23);
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 120px;
  min-height: 64px;
  background-color: #fff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-transform: none;
  padding: 0 5px;
  font-weight: 400;

  & > svg {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 14px;
  }
`;
