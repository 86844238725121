import axios from 'axios';
import {
  IClientProps,
  ICurrencyProps,
  IOfficeProps,
  IPracticeProps,
  IProfessionalProps,
} from './master';

const UNAUTHORIZED = 401;
const FORBBIDEN = 403;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const { status } = error.response;
      if (status === UNAUTHORIZED || status === FORBBIDEN) {
        localStorage.clear();
        window.location.href = '/';
      }
    }

    return Promise.reject(error);
  },
);
api.defaults.headers.common.Authorization = localStorage.getItem('accessToken');

export default api;

export const Permissions = {
  Admin: 'API.Legal.Admin',
  User: 'API.Legal.User',
};

export interface ContractType {
  id: number;
  contractTypeHighlightContentFields: number[];
  contractTypeContentFields: number[];
  isActive: boolean;
  highlightFieldsObjectArray: ContractTypeContentField[];
  description: string;
  contentField: ContentField;
}

export interface ContractTypeContentField {
  id: number;
  fieldName: string;
  idContentField: number;
  isHighlight: boolean;
  isAlertDate: boolean;
  isAlertAutoRenovation: boolean;
  isAlertNotice: boolean;
  isAlertUndeterminedPeriod: boolean;
  isShared: boolean;
  order: number;
  contentField: ContentField;
}

export interface Contract {
  id: number;
  contractTypeCategory: ContractTypeCategory;
  contractType: ContractType;
  contractContentField: ContractContentField[];
  companiesObject: ContractCompany[];
  createdAt: string;
  idCategory: number;
  idContractType: number;
  idParent?: number;
  firstAlertDate?: Date;
  secondAlertDate?: Date;
  thirdAlertDate?: Date;
}

export interface ContractCompany {
  id: number;
  tradeName: string;
  idCompany: number;
}

export interface ContractContentField {
  id: number;
  idContentField: number;
  idContract: number;
  fieldText?: string;
  fieldValue?: number;
  fieldDate?: string;
  fieldBool?: boolean;
  contentField: ContentField;
}

export interface ContractTypeCategory {
  id: number;
  description: string;
  isActive: boolean;
  contractType: ContractType;
}

export interface ContentField {
  id: number;
  fieldName: string;
  idDropdownList: number;
  idFieldType: number;
  isActive: boolean;
  isRequired: boolean;
  order: number;
}
