import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { Header, HeaderLogo } from './styles';

import LegalLogo from '../../assets/images/legal-logo.png';

import { useAuth } from '../../hooks/auth';

export const HeaderComponent: React.FC<{ hasTitle?: boolean }> = ({
  hasTitle,
}) => {
  const { user } = useAuth();
  const history = useHistory();
  return (
    <Header>
      {(hasTitle === undefined || hasTitle === true) && (
        <p>
          {history.location.pathname === '/app'
            ? 'HOME'
            : history.location.pathname
              .replace('/', ' ')
              .replace('admin/', ' ')
              .replace('-', ' ')
              .replace('-', ' ')
              .toUpperCase()}
        </p>
      )}
      <HeaderLogo>
        <img src={LegalLogo} className="salesLogo" alt="Sales cell logo" />
      </HeaderLogo>
    </Header>
  );
};
