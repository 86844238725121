import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { DataGrid, DateBox, FileManager, TagBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import { SelectBox } from 'devextreme-react/select-box';
import { FiCalendar, FiExternalLink } from 'react-icons/fi';
import { WhisperSpinner } from 'react-spinners-kit';
import { Permissions } from 'devextreme-react/file-manager';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import DataSource from 'devextreme/data/data_source';
import { IconButton, Tooltip } from '@material-ui/core';
import { Column, Paging } from 'devextreme-react/data-grid';
import { format, parseISO } from 'date-fns';
import { Container, Body, Section, HeaderStatus } from './styles';
import { useDocumentTitle } from '../../hooks/documentTitle';
import api, { ContractTypeContentField } from '../../services/api';
import Logo from '../../assets/images/legal-logo.png';
import { TextBox } from '../../components/TextBox';
import { MoneyBox } from '../../components/MoneyBox';
import { CheckBox } from '../../components/CheckBox';
import { useToast } from '../../hooks/toast';
import master from '../../services/master';
import { useAuth } from '../../hooks/auth';
import { SelectBoxSource } from '../../components/SelectBoxSource';
import { Button } from '../../components/Button';
import { FormGroup } from '../../components/FormGroup';
import legalGroups from '../../config/legalGroups';

import { DialogResetAlert } from './DialogResetAlert';

interface DocumentContentFieldProps {
  idContentField: number;
  fieldText?: string;
  fieldValue?: number;
  fieldBool?: boolean;
  fieldDate?: Date;
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const DocumentView: React.FC<{ title?: string }> = ({ title }) => {
  const { user, hasScopes } = useAuth();
  const { setTitle } = useDocumentTitle();
  const { addToast } = useToast();
  const { id: documentId } = useParams<{ id: string }>();
  const [documentInfo, setDocumentInfo] = useState<any>({});
  const [documentContentFields, setDocumentContentFields] = useState<
    DocumentContentFieldProps[]
  >([]);
  const [contractTypeHighlightFields, setContractTypeHighlightFields] =
    useState<any>();
  const [documentType, setDocumentType] = useState(0);
  const [documentTypeName, setDocumentTypeName] = useState('');
  const [documentCategory, setDocumentCategory] = useState(0);
  const [documentCompany, setDocumentCompany] = useState<number[]>([]);
  const [integrationGroups, setIntegrationGroups] = useState<number[]>([]);
  const [integrationGroupsSource, setIntegrationGroupsSource] =
    useState<DataSource>();
  const [contentFields, setContentFields] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [loadingExtesion, setLoadingExtension] = useState(false);
  const [hasSentAlerts, setHasSentAlerts] = useState(false);
  const [openResetAlert, setOpenResetAlert] = useState(false);

  const [categorySource, setCategorySource] = useState<CustomStore>();
  const [companiesSource, setCompaniesSource] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );
  const [extensionsSource, setExtensionsSource] = useState<DataSource>(
    new DataSource([]),
  );

  const [resetValue, setResetValue] = useState('');
  const [resetIdContentField, setResetIdContentField] = useState(0);
  const [resetField, setResetField] = useState('');

  window.name = `Window${documentId}`;

  const loadDocumentInfo = useCallback(async () => {
    setLoading(true);
    const response = await api.get(`/api/contracts/${documentId}`);
    setDocumentInfo(response.data);
    setDocumentContentFields(response.data.contractContentField);
    setDocumentType(response.data.idContractType);
    setContentFields(response.data.contractTypeContentField);
    setDocumentCategory(response.data.idCategory);
    setDocumentCompany(response.data.contractCompany);
    setIntegrationGroups(response.data.contractIntegrationGroup);
    setContractTypeHighlightFields(
      response.data.contractType.highlightFieldsObjectArray.filter(
        (x: any) => x.isHighlight,
      ),
    );
    setDocumentTypeName(response.data.contractType.description);
    setHasSentAlerts(
      !!response.data.firstAlertDate ||
        !!response.data.secondAlertDate ||
        !!response.data.thirdAlertDate,
    );

    setCategorySource(
      new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get(
            `/api/type-categories/type/${documentType}`,
          );
          return data;
        },
      }),
    );

    setCompaniesSource({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const responseCompanies = await master.get('/master/companies');
          return responseCompanies.data;
        },
      }),
      paginate: true,
    });

    setLoading(false);
  }, [documentId, documentType]);

  const loadIntegrationGroups = useCallback(async () => {
    const integrationGroupsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const response = await master.get(`/master/integration-groups`);
        return response.data;
      },
    });

    setIntegrationGroupsSource(
      new DataSource({
        store: integrationGroupsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  const loadExtensions = useCallback(async () => {
    const extensionsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/contracts/${documentId}/extensions`,
        );

        const extensions = data.map((x: any) => ({
          ...x,
          displayName: `Extension - ${x.id}`,
        }));
        return extensions;
      },
    });

    setExtensionsSource(
      new DataSource({
        store: extensionsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [documentId]);

  useEffect(() => {
    setTitle(title);
    loadExtensions();
    loadIntegrationGroups();
    loadDocumentInfo();
  }, [
    setTitle,
    title,
    loadDocumentInfo,
    loadIntegrationGroups,
    loadExtensions,
  ]);

  const handleDocumentInfoChange = useCallback(async () => {
    await api.put(`api/contracts/${documentId}`, {
      idContractType: documentType,
      idCategory: documentCategory,
      companies: documentCompany,
      idContractStatus: 1,
      createdBy: documentInfo.createdBy,
      createdAt: documentInfo.createdAt,
      updatedBy: user.userId,
      updatedAt: new Date(),
      integrationGroups,
    });

    addToast({
      type: 'success',
      title: 'Saved',
    });
  }, [
    documentId,
    documentType,
    documentCategory,
    documentCompany,
    documentInfo.createdBy,
    documentInfo.createdAt,
    user.userId,
    integrationGroups,
    addToast,
  ]);

  const handleContractContendFieldChange = useCallback(
    async (idContentField, type, value) => {
      await api.patch(
        `api/contracts/${documentId}/content-fields/${idContentField}`,
        {
          [type]: value,
        },
      );

      addToast({
        type: 'success',
        title: 'Saved',
      });
    },
    [addToast, documentId],
  );

  const handleContractContendFieldDateChange = useCallback(
    async (idContentField, isAlertDate, type, value) => {
      if (isAlertDate && hasSentAlerts) {
        setResetValue(value);
        setResetIdContentField(idContentField);
        setResetField(type);

        setOpenResetAlert(true);
        return;
      }

      await api.patch(
        `api/contracts/${documentId}/content-fields/${idContentField}`,
        {
          [type]: value,
        },
      );

      addToast({
        type: 'success',
        title: 'Saved',
      });
    },
    [addToast, documentId, hasSentAlerts],
  );

  const customFileProvider = useMemo(
    () =>
      new CustomFileSystemProvider({
        keyExpr: 'id',
        dateModifiedExpr: 'updatedAt',
        // Function to get file system items
        getItems: async pathInfo => {
          const params: { idParent: string } = {} as { idParent: string };

          if (pathInfo.key) params.idParent = pathInfo.key;

          if (documentInfo.idParent) {
            const { data } = await api.get(
              `/api/contracts/${documentInfo.idParent}/files`,
              {
                params,
              },
            );
            return data.map((file: any) => ({
              id: file.id,
              name: file.fileName,
              isDirectory: file.isDirectory,
              size: file.fileSize,
            }));
          }

          const { data } = await api.get(`/api/contracts/${documentId}/files`, {
            params,
          });

          return data.map((file: any) => ({
            id: file.id,
            name: file.fileName,
            isDirectory: file.isDirectory,
            size: file.fileSize,
          }));
        },
        // Functions to handle file operations
        createDirectory: async (parentDirectory, name) => {
          await api.post(`/api/contracts/${documentId}/files/directory`, {
            directoryName: name,
            idParent: parentDirectory.key,
          });
        },
        deleteItem: async item => {
          if (documentInfo.idParent) {
            await api.delete(
              `/api/contracts/${documentInfo.idParent}/files/${item.key}`,
            );
          } else {
            await api.delete(`/api/contracts/${documentId}/files/${item.key}`);
          }
        },
        renameItem: async (item, newName) => {
          const oldc = item.name.split('.');
          let olde = '';
          const newc = newName.split('.');
          let newe = '';
          if (oldc.length > 0) olde = oldc[oldc.length - 1];
          if (newc.length > 0) newe = newc[newc.length - 1];
          if (newe !== olde) {
            addToast({
              type: 'error',
              title: 'The file extension should be the same.',
            });
            throw new Error('The file extension should be the same.');
          } else if (documentInfo.idParent) {
            await api.put(
              `/api/contracts/${documentInfo.idParent}/files/${item.key}`,
              {
                name: newName,
              },
            );
          } else {
            await api.put(`/api/contracts/${documentId}/files/${item.key}`, {
              name: newName,
            });
          }
        },
        moveItem: async (item, directory) => {
          if (documentInfo.idParent) {
            await api.patch(
              `/api/contracts/${documentInfo.idParent}/files/${item.key}/parent`,
              {
                idParent: directory.key === '' ? null : directory.key,
              },
            );
          } else {
            await api.patch(
              `/api/contracts/${documentId}/files/${item.key}/parent`,
              {
                idParent: directory.key === '' ? null : directory.key,
              },
            );
          }
        },
        downloadItems: async items => {
          const ids = items.map(x => x.key);
          if (documentInfo.idParent) {
            const { data, headers } = await api.get(
              `/api/contracts/${
                documentInfo.idParent
              }/files/download?files=[${ids.join(',')}]`,
              {
                responseType: 'blob',
              },
            );

            const downloadUrl = window.URL.createObjectURL(new Blob([data]));

            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', headers['x-file-name']);
            document.body.appendChild(link);
            link.click();
            link.remove();
          } else {
            const { data, headers } = await api.get(
              `/api/contracts/${documentId}/files/download?files=[${ids.join(
                ',',
              )}]`,
              {
                responseType: 'blob',
              },
            );

            const downloadUrl = window.URL.createObjectURL(new Blob([data]));

            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', headers['x-file-name']);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        },
        uploadFileChunk: async (file, uploadInfo, directory) => {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('idParent', directory.key);
          if (documentInfo.idParent) {
            await api.post(
              `/api/contracts/${documentInfo.idParent}/files/file`,
              formData,
              {
                headers: {
                  'content-type': 'multipart/form-data',
                  'X-CHUNK-TOTAL': uploadInfo.chunkCount,
                  'X-CHUNK-INDEX': uploadInfo.chunkIndex + 1,
                },
              },
            );
          } else {
            await api.post(
              `/api/contracts/${documentId}/files/file`,
              formData,
              {
                headers: {
                  'content-type': 'multipart/form-data',
                  'X-CHUNK-TOTAL': uploadInfo.chunkCount,
                  'X-CHUNK-INDEX': uploadInfo.chunkIndex + 1,
                },
              },
            );
          }
        },
      }),
    [addToast, documentId, documentInfo.idParent],
  );

  const handleCreateExtension = useCallback(async () => {
    setLoadingExtension(true);
    await api.put(`/api/contracts/${documentId}/extensions`, {
      idContractType: documentType,
      idCategory: documentCategory,
      companies: documentCompany,
      idContractStatus: 1,
      createdBy: documentInfo.createdBy,
      createdAt: documentInfo.createdAt,
      updatedBy: user.userId,
      updatedAt: new Date(),
      integrationGroups,
    });
    loadExtensions();
    loadDocumentInfo();
    addToast({
      type: 'success',
      title: 'Extension created successfully!',
    });
    setLoadingExtension(false);
  }, [
    addToast,
    documentCategory,
    documentCompany,
    documentId,
    documentInfo.createdAt,
    documentInfo.createdBy,
    documentType,
    integrationGroups,
    loadDocumentInfo,
    loadExtensions,
    user.userId,
  ]);

  const openViewCell = useCallback(e => {
    return (
      <Tooltip title="Open Extension" aria-label="open">
        <IconButton
          aria-label="Open Extension"
          size="small"
          onClick={() => window.open(`/documents/${e.key}`, `Window${e.key}`)}
        >
          <FiExternalLink size={22} color="#8b0304" />
        </IconButton>
      </Tooltip>
    );
  }, []);

  const generateColumnContent = useCallback((e, field) => {
    let text = '';
    if (field.contentField.idFieldType === 3) {
      text = 'No';
    }
    e.data.contractContentField.map((x: any) => {
      if (x.idContentField === field.idContentField) {
        text =
          x.fieldValue ||
          x.fieldText ||
          (x.fieldDate !== undefined &&
            format(parseISO(x.fieldDate), 'MMM dd yyyy')) ||
          (x.fieldBool === true ? 'Yes' : 'No');

        if (field.contentField.idFieldType === 1) {
          text = x.fieldText;
        }
      }
    });
    return <p>{text}</p>;
  }, []);

  const allowEdit = useMemo(() => hasScopes([legalGroups.Admin]), [hasScopes]);

  return (
    <Container>
      {!loading && (
        <>
          <HeaderStatus>
            <div className="status-bar" />
          </HeaderStatus>
          <header>
            <div>
              <span className="title">Document Type:</span>
              <h1>{documentTypeName}</h1>
              {documentInfo.idParent && <span>Extension</span>}
            </div>
            <div>
              <img src={Logo} alt="Legal logo" />
            </div>
          </header>
        </>
      )}

      <Body>
        {loading && (
          <div
            style={{
              width: 'fit-content',
              margin: '15% auto',
            }}
          >
            <WhisperSpinner size={75} backColor="#8b0304" />
          </div>
        )}
        {!loading && (
          <>
            <Section>
              <h2>Details</h2>
              <hr />

              <div className="content">
                <FormGroup fieldSetLabel="Category">
                  <SelectBox
                    dataSource={categorySource}
                    displayExpr="description"
                    valueExpr="id"
                    defaultValue={documentCategory}
                    onValueChange={value => {
                      setDocumentCategory(value);
                    }}
                    onFocusOut={handleDocumentInfoChange}
                    stylingMode="outlined"
                    readOnly={!allowEdit}
                  />
                </FormGroup>
                <div className="inputGroup">
                  <FormGroup fieldSetLabel="Third-Party Company(ies)">
                    <TagBox
                      dataSource={companiesSource}
                      value={documentCompany}
                      displayExpr="TradeName"
                      valueExpr="ID"
                      onValueChanged={e => setDocumentCompany(e.value)}
                      stylingMode="outlined"
                      onFocusOut={handleDocumentInfoChange}
                      readOnly={!allowEdit}
                      searchEnabled
                      searchExpr={['TradeName', 'CorporateName']}
                    />
                  </FormGroup>
                  <FormGroup fieldSetLabel="Integration Company(ies)">
                    <TagBox
                      dataSource={integrationGroupsSource}
                      value={integrationGroups}
                      displayExpr="Name"
                      valueExpr="ID"
                      onValueChanged={e => setIntegrationGroups(e.value)}
                      stylingMode="outlined"
                      onFocusOut={handleDocumentInfoChange}
                      readOnly={!allowEdit}
                      searchEnabled
                      searchExpr={['Name']}
                    />
                  </FormGroup>
                  {contentFields &&
                    contentFields.length > 0 &&
                    contentFields.map(
                      (contentFieldObj: ContractTypeContentField) =>
                        contentFieldObj.contentField &&
                        contentFieldObj.contentField.isActive && (
                          <FormGroup key={contentFieldObj.contentField.id}>
                            <label>
                              {contentFieldObj.contentField.fieldName}
                              {contentFieldObj.contentField.isRequired && (
                                <b style={{ color: '#8b0304' }}>*</b>
                              )}
                              {contentFieldObj.isShared && (
                                <i
                                  style={{
                                    marginLeft: '5px',
                                    color: '#9379fd',
                                    fontStyle: 'italic',
                                  }}
                                >
                                  (shared)
                                </i>
                              )}
                            </label>

                            {contentFieldObj.contentField.idFieldType === 1 && (
                              <SelectBoxSource
                                contentField={contentFieldObj.contentField}
                                onChanged={value =>
                                  handleContractContendFieldChange(
                                    contentFieldObj.contentField.id,
                                    'fieldValue',
                                    value,
                                  )
                                }
                                value={
                                  documentContentFields.find(
                                    x =>
                                      x.idContentField ===
                                      contentFieldObj.contentField.id,
                                  )?.fieldValue || 0
                                }
                                searchEnabled
                                stylingMode="outlined"
                                readOnly={
                                  (contentFieldObj.isShared &&
                                    documentInfo.idParent) ||
                                  !allowEdit
                                }
                              />
                            )}
                            {contentFieldObj.contentField.idFieldType === 2 && (
                              <TextBox
                                value={
                                  documentContentFields.find(
                                    x =>
                                      x.idContentField ===
                                      contentFieldObj.contentField.id,
                                  )?.fieldText || ''
                                }
                                onChanged={value =>
                                  handleContractContendFieldChange(
                                    contentFieldObj.contentField.id,
                                    'fieldText',
                                    value,
                                  )
                                }
                                readOnly={
                                  (contentFieldObj.isShared &&
                                    documentInfo.idParent) ||
                                  !allowEdit
                                }
                              />
                            )}
                            {contentFieldObj.contentField.idFieldType === 3 && (
                              <CheckBox
                                value={
                                  documentContentFields.find(
                                    x =>
                                      x.idContentField ===
                                      contentFieldObj.contentField.id,
                                  )?.fieldBool || false
                                }
                                onChanged={value =>
                                  handleContractContendFieldChange(
                                    contentFieldObj.contentField.id,
                                    'fieldBool',
                                    value,
                                  )
                                }
                                readOnly={
                                  (contentFieldObj.isShared &&
                                    documentInfo.idParent) ||
                                  !allowEdit
                                }
                              />
                            )}
                            {contentFieldObj.contentField.idFieldType === 4 && (
                              <DateBox
                                openOnFieldClick
                                defaultValue={
                                  documentContentFields.find(
                                    x =>
                                      x.idContentField ===
                                      contentFieldObj.contentField.id,
                                  )?.fieldDate || null
                                }
                                onValueChanged={e =>
                                  handleContractContendFieldDateChange(
                                    contentFieldObj.contentField.id,
                                    contentFieldObj.isAlertDate,
                                    'fieldDate',
                                    e.value,
                                  )
                                }
                                dropDownButtonRender={() => <FiCalendar />}
                                stylingMode="outlined"
                                readOnly={
                                  (contentFieldObj.isShared &&
                                    documentInfo.idParent) ||
                                  !allowEdit
                                }
                              />
                            )}
                            {contentFieldObj.contentField.idFieldType === 5 && (
                              <TextBox
                                type="number"
                                value={
                                  documentContentFields.find(
                                    x =>
                                      x.idContentField ===
                                      contentFieldObj.contentField.id,
                                  )?.fieldValue || undefined
                                }
                                onChanged={value =>
                                  handleContractContendFieldChange(
                                    contentFieldObj.contentField.id,
                                    'fieldValue',
                                    value,
                                  )
                                }
                                readOnly={
                                  (contentFieldObj.isShared &&
                                    documentInfo.idParent) ||
                                  !allowEdit
                                }
                              />
                            )}
                            {contentFieldObj.contentField.idFieldType === 6 && (
                              <MoneyBox
                                value={
                                  documentContentFields.find(
                                    x =>
                                      x.idContentField ===
                                      contentFieldObj.contentField.id,
                                  )?.fieldValue || undefined
                                }
                                onChanged={value =>
                                  handleContractContendFieldChange(
                                    contentFieldObj.contentField.id,
                                    'fieldValue',
                                    value,
                                  )
                                }
                                readOnly={
                                  (contentFieldObj.isShared &&
                                    documentInfo.idParent) ||
                                  !allowEdit
                                }
                              />
                            )}
                          </FormGroup>
                        ),
                    )}
                </div>
              </div>
            </Section>
            <Section>
              <h2>Files</h2>
              <hr />

              <div className="content" style={{ padding: '15px 0' }}>
                <FileManager fileSystemProvider={customFileProvider}>
                  <Permissions
                    create={allowEdit}
                    move={allowEdit}
                    delete={allowEdit}
                    upload={allowEdit}
                    download
                    rename={allowEdit}
                  />
                </FileManager>
              </div>
            </Section>
            <Section>
              <h2>Extensions</h2>
              <hr />
              <div className="content" style={{ padding: '15px 0' }}>
                {!documentInfo.idParent && (
                  <>
                    {allowEdit && (
                      <Button
                        primary
                        className="createExtensionButton"
                        onClick={handleCreateExtension}
                        style={{ margin: '0 35px' }}
                        loading={loadingExtesion}
                      >
                        Create Extension
                      </Button>
                    )}

                    <DataGrid
                      style={{ margin: '20px 0', minWidth: '100%' }}
                      dataSource={extensionsSource}
                    >
                      <Paging />
                      <Column dataField="displayName" caption="Extension ID" />
                      {contractTypeHighlightFields?.map((field: any) => (
                        <Column
                          cellRender={e => generateColumnContent(e, field)}
                          caption={field.contentField.fieldName}
                          key={field.id}
                        />
                      ))}
                      <Column
                        cellRender={openViewCell}
                        width={60}
                        alignment="right"
                      />
                    </DataGrid>
                  </>
                )}
                {documentInfo.idParent && (
                  <Button
                    primary
                    className="createExtensionButton"
                    style={{
                      alignSelf: 'flex-start',
                      margin: '20px 0 10px 30px',
                    }}
                    onClick={() =>
                      window.open(
                        `/documents/${documentInfo.idParent}`,
                        `Window${documentInfo.idParent}`,
                      )
                    }
                  >
                    RETURN TO PARENT CONTRACT
                  </Button>
                )}
              </div>
            </Section>
          </>
        )}
      </Body>

      {openResetAlert && (
        <DialogResetAlert
          handleClose={() => {
            setOpenResetAlert(false);
            setResetField('');
            setResetIdContentField(0);
            setResetValue('');
          }}
          open={openResetAlert}
          documentId={Number(documentId)}
          idContentField={resetIdContentField}
          type={resetField}
          value={resetValue}
        />
      )}
    </Container>
  );
};
