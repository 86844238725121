import React from 'react';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { DocumentTitleProvider } from './documentTitle';

interface AuxProps {
  children: React.ReactNode;
}

export default function AppProvider({ children }: AuxProps) {
  return (
    <AuthProvider>
      <ToastProvider>
        <DocumentTitleProvider>
          {children}
        </DocumentTitleProvider>
      </ToastProvider>
    </AuthProvider>
  );
}
