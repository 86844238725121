import React, { useCallback, useEffect, useState } from 'react';

import { FiBriefcase, FiFile } from 'react-icons/fi';
import { Container, Body } from './styles';
import { HeaderComponent } from '../../components/Header';
import { useDocumentTitle } from '../../hooks/documentTitle';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { DocumentsReport } from './DocumentsReport';

export const Reports: React.FC<{ title?: string }> = ({ title }) => {
  const { addToast } = useToast();
  const [documentsReport, setDocumentsReport] = useState(false);

  const { setTitle } = useDocumentTitle();

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);

  const handleGenerateCompanies = useCallback(async () => {
    try {
      addToast({
        type: 'info',
        title: 'We are generating your report. It could take a while',
      });

      const response = await api.get(`api/reports/third-party-companies`, {
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-third-party-companies.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Your report is ready. Open or save it below',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Something were wrong with your report. Try again please.',
      });
    }
  }, [addToast]);

  return (
    <>
      <Container>
        <HeaderComponent />
        <Body>
          <section className="actions">
            <div onClick={() => setDocumentsReport(true)} role="none">
              <div className="imgBox">
                <FiFile size={48} />
                <h1>DOCUMENTS</h1>
              </div>
            </div>
            <div onClick={handleGenerateCompanies} role="none">
              <div className="imgBox">
                <FiBriefcase size={48} />
                <h1>THIRD PARTY COMPANIES</h1>
              </div>
            </div>
          </section>
        </Body>
      </Container>

      {documentsReport && (
        <DocumentsReport
          open={documentsReport}
          handleClose={() => setDocumentsReport(false)}
        />
      )}
    </>
  );
};
