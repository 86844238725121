import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import { SignIn } from '../pages/SignIn';
import { Home } from '../pages/Home';
import { Documents } from '../pages/Documents';
import { Reports } from '../pages/Reports';
import { Admin } from '../pages/Admin';
import { ApplicationLayers } from '../components/ApplicationLayers';
import { ContentFields } from '../pages/Admin/ContentFields';
import { Categories } from '../pages/Admin/Categories';
import { ContractTypes } from '../pages/Admin/ContractTypes';
import { CompanyTypes } from '../pages/Admin/CompanyTypes';
import { Companies } from '../pages/Companies';
import { DocumentView } from '../pages/DocumentView';
import { DocumentsTypeGridView } from '../pages/DocumentsTypeGridView';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/auth/signin" exact component={SignIn} />
    <Route path="/" exact component={SignIn} />

    <ApplicationLayers>
      <Route path="/app" isPrivate exact component={Home} title="Home" />
      <Route
        path="/documents"
        isPrivate
        exact
        component={Documents}
        title="Documents"
      />
      <Route
        path="/documents/type/:id"
        isPrivate
        exact
        component={DocumentsTypeGridView}
        title="Documents"
      />
      <Route
        path="/documents/:id"
        isPrivate
        exact
        component={DocumentView}
        title="Documents - Details"
      />
      <Route
        path="/third-party-companies"
        isPrivate
        exact
        component={Companies}
        title="Third-party companies"
      />
      <Route
        path="/reports"
        isPrivate
        exact
        component={Reports}
        title="Reports"
      />

      <Route path="/admin" isPrivate exact component={Admin} title="Admin" />
      <Route
        path="/admin/content-fields"
        isPrivate
        exact
        component={ContentFields}
        title="Content fields - Admin"
      />
      <Route
        path="/admin/categories"
        isPrivate
        exact
        component={Categories}
        title="Categories - Admin"
      />
      <Route
        path="/admin/document-types"
        isPrivate
        exact
        component={ContractTypes}
        title="Document types - Admin"
      />
      <Route
        path="/admin/company-types"
        isPrivate
        exact
        component={CompanyTypes}
        title="Company types - Admin"
      />
    </ApplicationLayers>
  </Switch>
);

export default Routes;
