import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
} from '@material-ui/core';

import { Button } from '../../../components/Button';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

interface DialogCustomProps extends DialogProps {
  handleClose(): void;
  idContentField: number;
  type: string;
  value: string;
  documentId: number;
}

export const DialogResetAlert: React.FC<DialogCustomProps> = ({
  handleClose,
  open,
  documentId,
  idContentField,
  type,
  value,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const handleUpdate = useCallback(
    async (resetAlerts: boolean) => {
      setLoading(true);
      try {
        await api.patch(
          `api/contracts/${documentId}/content-fields/${idContentField}`,
          {
            [type]: value,
            resetAlerts,
          },
        );

        addToast({
          title: 'Saved',
          type: 'success',
        });

        handleClose();
      } catch {
        addToast({
          title: 'Something went wrong',
          type: 'error',
        });
      }

      setLoading(false);
    },
    [documentId, idContentField, type, value, handleClose, addToast],
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Alerts reset</DialogTitle>
      <DialogContent>
        <p>Do you want to reset all alerts for this contract?</p>
        <p style={{ fontWeight: 700 }}>
          If you don&apos;t reset the alerts, new alerts with updated date
          won&apos;t be sent.
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleUpdate(false)}
          loading={loading}
          disabled={loading}
        >
          Only update date
        </Button>
        <Button
          onClick={() => handleUpdate(true)}
          primary
          loading={loading}
          disabled={loading}
        >
          Reset and update date
        </Button>
      </DialogActions>
    </Dialog>
  );
};
