import axios from 'axios';

const UNAUTHORIZED = 401;
const FORBBIDEN = 403;

const api = axios.create({
  baseURL: process.env.REACT_APP_MASTER_API_URL,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const { status } = error.response;
      if (status === UNAUTHORIZED || status === FORBBIDEN) {
        localStorage.clear();
        window.location.href = '/';
      }
    }

    return Promise.reject(error);
  },
);
api.defaults.headers.common.Authorization = localStorage.getItem('accessToken');

export default api;

export interface IProfessionalProps {
  id: number;
  name: string;
  login: string;
  email: string;
  jobTitle: IJobTitleProps;
  JobTitle: IJobTitleProps;
  offices: IOfficeProps[];
  Offices: IOfficeProps[];
  practices: IPracticeProps[];
  Practices: IPracticeProps[];
  IsActive: boolean;
  isActive: boolean;
  Name: string;
  Login: string;
}

export interface IJobTitleProps {
  id: number;
  name: string;
  Name: string;
}

export interface IOfficeProps {
  id: number;
  name: string;
  IsActive: boolean;
  isActive: boolean;
  Name: string;
}

export interface IPracticeProps {
  id: number;
  name: string;
  IsActive: boolean;
  Area: string;
  Name: string;
}

export interface ICurrencyProps {
  id: number;
  name: string;
  abbreviation: string;
  IsActive: boolean;
}

export interface IClientProps {
  id: number;
  name: string;
  IsActive: boolean;
  isActive: boolean;
  Name: string;
}

export interface IClientEconomicGroupProps {
  ID: number;
  Name: string;
}

export interface IClietSegmentsProps {
  ID: number;
  SegmentLevel: number;
  Segment: ISegmentProps;
}

export interface ISegmentProps {
  ID: number;
  Name: string;
}

export interface ISignIn {
  displayName: string;
  scopes: string[];
  comanagementId: string;
  practiceId: string;
  professionalId: string;
  accessToken: string;
  userPrincipalName: string;
  email: string;
}
