import React, { useCallback, useEffect, useState } from 'react';

import {
  DataGrid,
  Column,
  Editing,
  Lookup,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { WhisperSpinner } from 'react-spinners-kit';
import { Container, Body, ContainerLoading } from './styles';
import { HeaderComponent } from '../../../components/Header';
import { useDocumentTitle } from '../../../hooks/documentTitle';
import api from '../../../services/api';

export const ContentFields: React.FC<{ title?: string }> = ({ title }) => {
  const { setTitle } = useDocumentTitle();
  const [firstLoading, setFirstLoading] = useState(true);
  const [contentFields, setContentFields] = useState<DataSource>();
  const [contentFieldTypes, setContentFieldTypes] = useState<CustomStore>();
  const [dropdownLists, setDropdownLists] = useState<CustomStore>();

  const loadContentFields = useCallback(async () => {
    const contentFieldsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/content-fields');
        setFirstLoading(false);
        return data;
      },
      insert: async data => {
        await api.post('/api/content-fields', data);
      },
      update: async (id, data) => {
        await api.put(`/api/content-fields/${id}`, data);
      },
    });

    setContentFields(
      new DataSource({
        store: contentFieldsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  const loadContentFieldTypes = useCallback(async () => {
    setContentFieldTypes(
      new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get('/api/content-field-types');
          return data;
        },
      }),
    );
  }, []);

  const loadDropdownLists = useCallback(async () => {
    setDropdownLists(
      new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get('/api/dropdown-lists');
          return data;
        },
      }),
    );
  }, []);

  useEffect(() => {
    setTitle(title);
    loadContentFieldTypes();
    loadDropdownLists();
    loadContentFields();
  }, [
    setTitle,
    title,
    loadContentFields,
    loadContentFieldTypes,
    loadDropdownLists,
  ]);

  const onToolbarPreparing = useCallback(e => {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach((item: any) => {
      if (item.name === 'addRowButton') {
        item.showText = 'always';
        item.options = {
          ...item.options,
          text: 'NEW CONTENT FIELD',
        };
      }
    });
  }, []);

  return (
    <Container>
      <HeaderComponent />
      {firstLoading && (
        <ContainerLoading>
          <WhisperSpinner size={50} backColor="#8b0304" />
        </ContainerLoading>
      )}
      <Body>
        <DataGrid
          className="grid"
          dataSource={contentFields}
          onToolbarPreparing={onToolbarPreparing}
          onRowUpdating={options => {
            options.newData = { ...options.oldData, ...options.newData };
          }}
        >
          <HeaderFilter visible allowSearch />
          <Column dataField="fieldName" />
          <Column
            dataField="idFieldType"
            caption="Field Type"
            alignment="center"
          >
            <Lookup
              displayExpr="description"
              valueExpr="id"
              dataSource={contentFieldTypes}
            />
          </Column>
          <Column
            dataField="idDropdownList"
            caption="Dropdown List (if applicable)"
            alignment="center"
          >
            <Lookup
              allowClearing
              displayExpr="description"
              valueExpr="id"
              dataSource={dropdownLists}
            />
          </Column>
          <Column dataField="isRequired" width={150} caption="Is Obligatory" />
          <Column dataField="isActive" width={120} />
          <Editing allowUpdating allowAdding />
        </DataGrid>
      </Body>
    </Container>
  );
};
