import React, { useCallback, useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
} from '@material-ui/core';

import { TextBox } from 'devextreme-react/text-box';

import { SelectBox } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Button } from '../../../../components/Button';
import { FormGroup } from '../../../../components/FormGroup';

import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

interface DialogCustomProps extends DialogProps {
  handleClose(): void;
  categories: DataSource;
}

export const DialogAddCategory: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  categories,
}) => {
  const { addToast } = useToast();
  const [fillRest, setFillRest] = useState(false);
  const [name, setName] = useState('');
  const [contractType, setContractType] = useState(0);
  const [contractTypeSource, setContractTypeSource] = useState<DataSource>();

  const loadContractTypes = useCallback(async () => {
    const contractTypesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/contract-types');
        return data;
      },
    });

    setContractTypeSource(
      new DataSource({
        store: contractTypesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadContractTypes();
  }, [loadContractTypes]);

  const handlePostCategory = useCallback(async () => {
    if (name !== '' && contractType !== 0) {
      await api.post(`/api/type-categories`, {
        description: name,
        idContractType: contractType,
        isActive: true,
      });
      categories.reload();
      handleClose();
      addToast({
        title: 'Category created successfully!',
        type: 'success',
      });
    } else {
      setFillRest(true);
    }
  }, [addToast, categories, contractType, handleClose, name]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setFillRest(false);
        handleClose();
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Add Category</DialogTitle>
      <DialogContent>
        <>
          <FormGroup fieldSetLabel="Name">
            <TextBox onValueChange={e => setName(e)} stylingMode="outlined" />
          </FormGroup>

          <FormGroup fieldSetLabel="Document Type">
            <SelectBox
              dataSource={contractTypeSource}
              displayExpr="description"
              valueExpr="id"
              onValueChanged={e => setContractType(e.value)}
              stylingMode="outlined"
            />
          </FormGroup>

          {fillRest && (
            <p style={{ color: 'red', fontWeight: 500 }}>
              Please fill in all the fields.
            </p>
          )}
        </>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setFillRest(false);
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button onClick={handlePostCategory} primary>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
