import React from 'react';

import { Container } from './styles';
import { getYear } from 'date-fns';

export const Footer: React.FC = () => {
  const year = getYear(new Date());
  return (
    <Container>
      INTEGRATION CONSULTING © {year}
    </Container>
  );
}
