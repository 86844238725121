import React, { useCallback, useEffect } from 'react';

import { FiEdit3, FiGrid, FiLayers, FiList } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Container, Body } from './styles';
import { HeaderComponent } from '../../components/Header';
import { useDocumentTitle } from '../../hooks/documentTitle';

export const Admin: React.FC<{ title?: string }> = ({ title }) => {
  const { setTitle } = useDocumentTitle();

  const history = useHistory();

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);

  const handleContentFields = useCallback(() => {
    history.push('/admin/content-fields');
  }, [history]);

  const handleCategories = useCallback(() => {
    history.push('/admin/categories');
  }, [history]);

  const handleContractType = useCallback(() => {
    history.push('/admin/document-types');
  }, [history]);

  const handleCompanyTypes = useCallback(() => {
    history.push('/admin/company-types');
  }, [history]);

  return (
    <Container>
      <HeaderComponent />
      <Body>
        <section className="actions">
          <div>
            <div
              className="imgBox"
              onClick={handleContractType}
              aria-hidden="true"
            >
              <FiLayers fontSize="62px" color="#dddddd" />
              <h1>Document Types</h1>
            </div>
          </div>
          <div>
            <div
              className="imgBox"
              onClick={handleCategories}
              aria-hidden="true"
            >
              <FiGrid fontSize="62px" color="#dddddd" />
              <h1>Categories</h1>
            </div>
          </div>
          <div>
            <div
              className="imgBox"
              onClick={handleContentFields}
              aria-hidden="true"
            >
              <FiEdit3 fontSize="62px" color="#dddddd" />
              <h1>Content Fields</h1>
            </div>
          </div>
          <div>
            <div
              className="imgBox"
              onClick={handleCompanyTypes}
              aria-hidden="true"
            >
              <FiList fontSize="62px" color="#dddddd" />
              <h1>Company Types</h1>
            </div>
          </div>
        </section>
      </Body>
    </Container>
  );
};
