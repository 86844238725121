import React, { useCallback, useEffect, useState } from 'react';

import { DataGrid, Column, HeaderFilter } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { FiEdit2, FiPlus } from 'react-icons/fi';
import { IconButton, Tooltip } from '@material-ui/core';
import { WhisperSpinner } from 'react-spinners-kit';
import { Container, Body } from './styles';
import { HeaderComponent } from '../../../components/Header';
import { useDocumentTitle } from '../../../hooks/documentTitle';
import api from '../../../services/api';
import { Button } from '../../../components/Button';
import { DialogEditContractType } from './DialogEditContractType';
import { DialogAddContractType } from './DialogAddContractType';
import { ContainerLoading } from '../styles';

export const ContractTypes: React.FC<{ title?: string }> = ({ title }) => {
  const { setTitle } = useDocumentTitle();
  const [firstLoading, setFirstLoading] = useState(true);
  const [contractTypes, setContractTypes] = useState<DataSource>(
    new DataSource([]),
  );
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editKey, setEditKey] = useState(1);

  const loadContractTypes = useCallback(async () => {
    const contractTypesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/contract-types');
        setFirstLoading(false);
        return data;
      },
    });

    setContractTypes(
      new DataSource({
        store: contractTypesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    setTitle(title);
    loadContractTypes();
  }, [setTitle, title, loadContractTypes]);

  const editCell = useCallback(e => {
    return (
      <Tooltip title="Edit Document Type" aria-label="open">
        <IconButton
          aria-label="Edit Document Type"
          size="small"
          onClick={() => {
            setEditKey(e.key);
            setOpenEdit(true);
          }}
        >
          <FiEdit2 size={20} color="#8b0304" />
        </IconButton>
      </Tooltip>
    );
  }, []);

  return (
    <Container>
      <HeaderComponent />
      {firstLoading && (
        <ContainerLoading>
          <WhisperSpinner size={50} backColor="#8b0304" />
        </ContainerLoading>
      )}
      <Body>
        <div className="gridContainer">
          <Button
            primary
            className="newContractTypeButton"
            onClick={() => setOpenAdd(true)}
          >
            <FiPlus size={20} />
            NEW DOCUMENT TYPE
          </Button>
          <DataGrid className="grid" dataSource={contractTypes}>
            <HeaderFilter visible allowSearch />
            <Column dataField="description" />
            <Column dataField="hasAlert" caption="Has Alerts?" />
            <Column dataField="isActive" width={120} />
            <Column width={80} cellRender={editCell} />
          </DataGrid>
        </div>
      </Body>

      <DialogAddContractType
        open={openAdd}
        handleClose={() => setOpenAdd(false)}
        contractTypes={contractTypes}
      />

      <DialogEditContractType
        open={openEdit}
        contractTypeId={editKey}
        handleClose={() => setOpenEdit(false)}
        contractTypes={contractTypes}
      />
    </Container>
  );
};
