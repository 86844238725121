import { Drawer } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Drawer)`
  position: relative;
`;

export const Actions = styled.div`
  display: flex;
  position: absolute;
  right: 15px;
  bottom: 15px;
`;
