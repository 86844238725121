import styled from 'styled-components';

export const Container = styled.div`
  a {
    width: 100%;
    color: inherit;
    text-decoration: none;
  }

  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Body = styled.section`
  width: 100%;
  background-color: transparent;

  .grid {
    .dx-datagrid-header-panel {
      background-color: #efefef;
      border: none;

      .dx-toolbar {
        background-color: #efefef;
      }
    }

    .dx-header-row {
      background-color: #efefef;
      border: 0;

      .dx-datagrid-text-content {
        font-size: 14px;
        color: black;
        font-weight: bold;
      }
    }

    .dx-datagrid-action {
      color: #bbbbbb;
      font-weight: bold;
      text-transform: uppercase;
    }

    .dx-data-row {
      color: #333333;
      font-weight: 700;
    }

    .dx-datagrid-addrow-button {
      background-color: #8b0304;
      color: white;
    }

    .dx-datagrid-addrow-button.dx-state-hover {
      background-color: #650001;
    }

    .dx-icon-edit-button-addrow {
      color: white !important;
    }
  }
`;

export const ContainerLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #efefef;
  height: 100vh;
`;
