import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography, FormControl } from '@material-ui/core';
import { DateBox, SelectBox } from 'devextreme-react';
import { CheckBox } from 'devextreme-react/check-box';

import { FiCalendar } from 'react-icons/fi';
import { format } from 'date-fns';
import CustomStore from 'devextreme/data/custom_store';
import { Container, Actions } from './styles';
import { useToast } from '../../../hooks/toast';

import { Button } from '../../../components/Button';
import api from '../../../services/api';
import { FormGroup } from '../../../components/FormGroup';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
}

export const DocumentsReport: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
}) => {
  const { addToast } = useToast();
  const [documentType, setDocumentType] = useState(0);
  const [hasDocumentType, setHasDocumentType] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [onlyHighlight, setOnlyHighlight] = useState(false);

  const [contractTypeSource, setContractTypeSource] = useState<CustomStore>();

  const loadContractTypes = useCallback(async () => {
    setContractTypeSource(
      new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get('/api/contract-types');
          return data;
        },
      }),
    );
  }, []);

  useEffect(() => {
    loadContractTypes();
  }, [loadContractTypes]);

  const handleGenerate = useCallback(async () => {
    if (documentType === 0) {
      setHasDocumentType(false);
    } else {
      setHasDocumentType(true);
      try {
        addToast({
          type: 'info',
          title: 'We are generating your report. It could take a while',
        });

        const response = await api.get(
          `api/reports/contracts?type=${documentType}&startDate=${startDate}&endDate=${endDate}&onlyHighlight=${onlyHighlight}`,
          {
            responseType: 'blob',
          },
        );

        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data]),
        );

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `documentsreport.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        addToast({
          type: 'success',
          title: 'Your report is ready. Open or save it below',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Something were wrong with your report. Try again please.',
        });
      }
    }
  }, [addToast, documentType, endDate, onlyHighlight, startDate]);

  return (
    <Container anchor="right" open={open}>
      <Box m={2} minWidth={500}>
        <Typography
          variant="h5"
          component="h5"
          style={{ fontWeight: 500, marginBottom: '15px' }}
        >
          Documents report
        </Typography>

        <FormControl style={{ width: '100%', marginTop: 15 }}>
          <FormGroup fieldSetLabel="Document Type" isRequired>
            <SelectBox
              dataSource={contractTypeSource}
              stylingMode="outlined"
              displayExpr="description"
              valueExpr="id"
              onValueChange={value => setDocumentType(value)}
            />
          </FormGroup>
        </FormControl>

        <FormControl style={{ width: '100%', marginTop: 15 }}>
          <FormGroup fieldSetLabel="Start Date">
            <DateBox
              openOnFieldClick
              dropDownButtonRender={() => <FiCalendar />}
              stylingMode="outlined"
              onValueChanged={e => setStartDate(format(e.value, 'MM/dd/yyyy'))}
            />
          </FormGroup>
        </FormControl>

        <FormControl style={{ width: '100%', marginTop: 15 }}>
          <FormGroup fieldSetLabel="End Date">
            <DateBox
              openOnFieldClick
              dropDownButtonRender={() => <FiCalendar />}
              stylingMode="outlined"
              onValueChanged={e => setEndDate(format(e.value, 'MM/dd/yyyy'))}
              min={startDate}
            />
          </FormGroup>
        </FormControl>

        <FormControl style={{ width: '100%', marginTop: 15 }}>
          <FormGroup fieldSetLabel="Only Highlight Fields">
            <CheckBox
              value={onlyHighlight}
              onValueChange={(value: boolean) => setOnlyHighlight(value)}
            />
          </FormGroup>
        </FormControl>

        <p
          style={{
            color: 'red',
            display: hasDocumentType === true ? 'none' : 'block',
          }}
        >
          Please select a document type.
        </p>

        <Actions>
          <Button onClick={handleClose} style={{ marginRight: 15 }}>
            Cancel
          </Button>

          <Button onClick={handleGenerate} primary>
            Generate
          </Button>
        </Actions>
      </Box>
    </Container>
  );
};
