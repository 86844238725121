export const config = {
  clientId: process.env.REACT_APP_AD_APP_ID,
  tenantId: process.env.REACT_APP_AD_TENANT_ID,
  redirectUri: process.env.REACT_APP_AD_REDIRECT_URI,
  redirectUriSignout: process.env.REACT_APP_AD_REDIRECT_URI_SIGNOUT,
  nonce: '99fafe434c2f56dbc29f2a37fa7a930c',
  scopes: [
    `api://${process.env.REACT_APP_MASTER_API_CLIENT_ID}/user.read`,
  ]
};
