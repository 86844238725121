import React, { useState, useCallback, useEffect } from 'react';

import {
  CheckBox as CheckBoxDev,
  ICheckBoxOptions,
} from 'devextreme-react/check-box';
import { Container } from './styles';

interface CheckBoxProps extends ICheckBoxOptions {
  onChanged(value: string): void;
  value: any;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  value: defaultValue,
  onChanged,
  readOnly: _readOnly,
  ...rest
}) => {
  const [value, setValue] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setReadOnly(_readOnly || false);
  }, [_readOnly]);

  const handleChange = useCallback(
    e => {
      setValue(e.value);
      onChanged(e.value);
    },
    [onChanged],
  );

  return (
    <Container>
      <CheckBoxDev
        value={value}
        readOnly={readOnly}
        onValueChanged={handleChange}
        {...rest}
      />
    </Container>
  );
};
