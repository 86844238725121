import styled from 'styled-components';

export const Container = styled.div`
  a {
    width: 100%;
    color: inherit;
    text-decoration: none;
  }

  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Body = styled.section`
  width: 100%;
  background-color: transparent;

  .categorySelect {
    h1 {
      text-align: center;
      font-size: larger;
      color: #4a4a4a;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .select {
      width: 50%;
      margin: 0 auto;
      outline: none;
      margin-bottom: 30px;
    }
  }

  .newDocButton {
    margin: 0 auto;
    width: 50%;
    justify-content: center;
  }
`;
