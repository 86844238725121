import React from 'react';

import { Container } from './styles';

import { Surface } from '../Surface';
import { ApplicationContent } from '../ApplicationContent';
import { Footer } from '../Footer';

export const ApplicationLayers: React.FC = ({ children }) => {
  return (
    <Container>
      <Surface />

      <ApplicationContent>
        {children}

        <Footer />
      </ApplicationContent>
    </Container>
  )
}
