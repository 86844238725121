import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import {
  FiHome,
  FiBarChart2,
  FiFile,
  FiLogOut,
  FiSettings,
  FiBriefcase,
} from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';

import {
  Container,
  Control,
  ControlComponent,
  NavigationItemsArea,
  ActionIconWrapper,
  NotificationsComponent,
  NavigationDialogItemComponent,
} from './styles';
import legalGroups from '../../config/legalGroups';

export const Surface: React.FC = () => {
  const history = useHistory();

  const { signOut, hasScopes } = useAuth();

  const handleHome = useCallback(() => {
    history.push('/');
  }, [history]);

  const handleDocuments = useCallback(() => {
    history.push('/documents');
  }, [history]);

  const handleCompanies = useCallback(() => {
    history.push('/third-party-companies');
  }, [history]);

  const handleReports = useCallback(() => {
    history.push('/reports');
  }, [history]);

  const handleAdmin = useCallback(() => {
    history.push('/admin');
  }, [history]);

  return (
    <>
      <Container>
        <Control>
          <ControlComponent>
            <NavigationItemsArea>
              <ActionIconWrapper>
                <NotificationsComponent>
                  <span>
                    <div className="iconContainer">
                      <Tooltip title="Home" placement="right" color="red">
                        <NavigationDialogItemComponent onClick={handleHome}>
                          <FiHome color="#9D9D9D" />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </div>
                    <div className="iconContainer">
                      <Tooltip title="Documents" placement="right" color="red">
                        <NavigationDialogItemComponent
                          onClick={handleDocuments}
                        >
                          <FiFile color="#9D9D9D" />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </div>
                    <div className="iconContainer">
                      <Tooltip
                        title="Third-Party Companies"
                        placement="right"
                        color="red"
                      >
                        <NavigationDialogItemComponent
                          onClick={handleCompanies}
                        >
                          <FiBriefcase color="#9D9D9D" />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </div>
                    <div className="iconContainer">
                      <Tooltip title="Reports" placement="right" color="red">
                        <NavigationDialogItemComponent onClick={handleReports}>
                          <FiBarChart2 color="#9D9D9D" />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </div>
                    {hasScopes([legalGroups.Admin]) && (
                      <div className="iconContainer">
                        <Tooltip title="Admin" placement="right">
                          <NavigationDialogItemComponent onClick={handleAdmin}>
                            <FiSettings color="#9D9D9D" />
                          </NavigationDialogItemComponent>
                        </Tooltip>
                      </div>
                    )}
                    <div className="iconContainer">
                      <Tooltip title="Log out" placement="right">
                        <NavigationDialogItemComponent
                          onClick={signOut}
                          style={{ position: 'absolute', bottom: 32 }}
                        >
                          <FiLogOut color="#9D9D9D" />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </div>
                  </span>
                </NotificationsComponent>
              </ActionIconWrapper>
            </NavigationItemsArea>
          </ControlComponent>
        </Control>
      </Container>
    </>
  );
};
