import React, { useCallback, useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
} from '@material-ui/core';

import { TextBox } from 'devextreme-react/text-box';

import DataSource from 'devextreme/data/data_source';
import { Button } from '../../../../components/Button';
import { FormGroup } from '../../../../components/FormGroup';

import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

interface DialogCustomProps extends DialogProps {
  handleClose(): void;
  contractTypes: DataSource;
}

export const DialogAddContractType: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  contractTypes,
}) => {
  const { addToast } = useToast();
  const [fillRest, setFillRest] = useState(false);
  const [name, setName] = useState('');
  const [defaultFolders, setDefaultFolders] = useState('');

  const handlePostContractType = useCallback(async () => {
    if (name !== '' && defaultFolders !== '') {
      await api.post(`/api/contract-types`, {
        description: name,
        defaultFolders,
        isActive: true,
      });
      contractTypes.reload();
      handleClose();
      addToast({
        title: 'Document type created successfully!',
        type: 'success',
      });
      setFillRest(false);
    } else {
      setFillRest(true);
    }
  }, [addToast, contractTypes, defaultFolders, handleClose, name]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setFillRest(false);
        handleClose();
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Add Document Type</DialogTitle>
      <DialogContent>
        <>
          <FormGroup fieldSetLabel="Name">
            <TextBox onValueChange={e => setName(e)} stylingMode="outlined" />
          </FormGroup>

          <FormGroup fieldSetLabel="Default Folders">
            <p
              style={{
                color: 'darkgrey',
                fontWeight: 500,
                marginBottom: '10px',
              }}
            >
              The default folders should be separated by commas and without
              spaces in between them, exactly as shown in the following example:
              addendum,policies,nda
            </p>
            <TextBox
              onValueChange={e => setDefaultFolders(e)}
              stylingMode="outlined"
            />
          </FormGroup>

          {fillRest && (
            <p style={{ color: 'red', fontWeight: 500 }}>
              Please fill in all the fields.
            </p>
          )}
        </>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setFillRest(false);
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button onClick={handlePostContractType} primary>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
