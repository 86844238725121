import React, { useState, useCallback, useEffect } from 'react';

import {
  SelectBox as SelectBoxDev,
  ISelectBoxOptions,
} from 'devextreme-react/select-box';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Container } from './styles';
import master from '../../services/master';
import api from '../../services/api';

interface SelectBoxProps extends ISelectBoxOptions {
  onChanged(value: string): void;
  value: any;
  contentField: any;
}

export const SelectBoxSource: React.FC<SelectBoxProps> = ({
  value: defaultValue,
  onChanged,
  contentField,
  readOnly: _readOnly,
  ...rest
}) => {
  const [value, setValue] = useState('');
  const [readOnly, setReadOnly] = useState(false);
  const [selectBoxDataSource, setSelectBoxDataSource] = useState<DataSource>();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setReadOnly(_readOnly || false);
  }, [_readOnly]);

  const handleChange = useCallback(
    e => {
      setValue(e.value);
      onChanged(e.value);
    },
    [onChanged],
  );

  const defineSelectBoxDataSource = useCallback(async () => {
    let dropdownStore;

    if (contentField.dropdownList.isCallFromMaster) {
      dropdownStore = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          const { data } = await master.get(
            `/master/${contentField.dropdownList.resource.toLowerCase()}`,
          );
          return data;
        },
      });
    } else {
      dropdownStore = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get(
            `/api/${contentField.dropdownList.resource.toLowerCase()}`,
          );
          return data;
        },
      });
    }

    setSelectBoxDataSource(
      new DataSource({
        store: dropdownStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [
    contentField.dropdownList.isCallFromMaster,
    contentField.dropdownList.resource,
  ]);

  useEffect(() => {
    defineSelectBoxDataSource();
  }, [defineSelectBoxDataSource]);

  return (
    <Container>
      <SelectBoxDev
        dataSource={selectBoxDataSource}
        displayExpr={contentField.dropdownList.displayExprString}
        valueExpr={contentField.dropdownList.valueExprString}
        stylingMode="outlined"
        value={value}
        onValueChanged={handleChange}
        readOnly={readOnly}
        {...rest}
      />
    </Container>
  );
};
