import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    width: 100%;

    h1 {
      color: #333;
      letter-spacing: 0.1em;
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
    }

    div {
      display: flex;
      flex-direction: column;
    }

    span {
      font-size: 16px;
      color: #999999;
      font-weight: 700;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    .title {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0;
      margin-bottom: -5px;
    }

    img {
      max-width: 100px;
    }
  }
`;

export const Body = styled.section`
  width: 100%;
  background-color: transparent;

  .bodyContainer {
    display: flex;
    flex-direction: column;
  }
`;

export const Photo = styled.img`
  max-width: 42px;
  border-radius: 50%;
`;

export const Section = styled.section`
  width: 100%;
  margin: 15px 0;

  h2 {
    color: #aaaaaa;
    letter-spacing: 0.1em;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 35px;
  }

  hr {
    margin: 0;
    border-top: 0;
    border-left: 0;
    border-bottom: 2px solid #dddddd;
  }

  .content {
    width: 100%;
    padding: 15px 35px;
  }
`;

export const HeaderStatus = styled.div`
  width: 100%;
  text-align: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  & > .status-bar {
    position: absolute;
    left: 0;
    right: 0;
    height: 10px;
    top: 0;
    background-color: #333;
    z-index: 999;
  }

  .content {
    margin: 10px 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    & > svg {
      cursor: pointer;
    }

    p {
      font-weight: 700;
      font-size: 24px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #333;
    }

    span {
      font-size: 16px;
      color: #ddd;
      font-weight: 700;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }
`;
