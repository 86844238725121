import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { FiFile, FiBarChart2, FiSettings, FiBriefcase } from 'react-icons/fi';
import { Container, Body } from './styles';
import { HeaderComponent } from '../../components/Header';
import { useDocumentTitle } from '../../hooks/documentTitle';
import { useAuth } from '../../hooks/auth';
import legalGroups from '../../config/legalGroups';

export const Home: React.FC<{ title?: string }> = ({ title }) => {
  const { setTitle } = useDocumentTitle();

  const { hasScopes } = useAuth();

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);

  return (
    <Container>
      <HeaderComponent />
      <Body>
        <section className="welcomeHeader">
          <h1>welcome to the integration legal system</h1>
          <hr />
        </section>
        <section className="actions">
          <Link to="/documents">
            <div>
              <div className="imgBox">
                <FiFile size="62px" color="#dddddd" />
                <h1>DOCUMENTS</h1>
              </div>
            </div>
          </Link>
          <Link to="/third-party-companies">
            <div>
              <div className="imgBox">
                <FiBriefcase size="62px" color="#dddddd" />
                <h1>THIRD-PARTY COMPANIES</h1>
              </div>
            </div>
          </Link>
          <Link to="/reports">
            <div>
              <div className="imgBox">
                <FiBarChart2 size="62px" color="#dddddd" />
                <h1>REPORTS</h1>
              </div>
            </div>
          </Link>
          {hasScopes([legalGroups.Admin]) && (
            <Link to="/admin">
              <div>
                <div className="imgBox">
                  <FiSettings size="62px" color="#dddddd" />
                  <h1>ADMIN</h1>
                </div>
              </div>
            </Link>
          )}
        </section>
      </Body>
    </Container>
  );
};
