import React, { useCallback, useEffect, useState } from 'react';

import {
  DataGrid,
  Column,
  Editing,
  Lookup,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { WhisperSpinner } from 'react-spinners-kit';
import { Container, Body, ContainerLoading } from './styles';
import { HeaderComponent } from '../../../components/Header';
import { useDocumentTitle } from '../../../hooks/documentTitle';
import master from '../../../services/master';

export const CompanyTypes: React.FC<{ title?: string }> = ({ title }) => {
  const { setTitle } = useDocumentTitle();
  const [firstLoading, setFirstLoading] = useState(true);
  const [companyTypes, setCompanyTypes] = useState<DataSource>();

  const loadCompanyTypes = useCallback(async () => {
    const companyTypesStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/company-types');
        setFirstLoading(false);
        return data;
      },
      insert: async data => {
        await master.post('/master/company-types', data);
      },
      update: async (id, data) => {
        await master.put(`/master/company-types/${id}`, data);
      },
    });

    setCompanyTypes(
      new DataSource({
        store: companyTypesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    setTitle(title);
    loadCompanyTypes();
  }, [setTitle, title, loadCompanyTypes]);

  const onToolbarPreparing = useCallback(e => {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach((item: any) => {
      if (item.name === 'addRowButton') {
        item.showText = 'always';
        item.options = {
          ...item.options,
          text: 'NEW COMPANY TYPE',
        };
      }
    });
  }, []);

  return (
    <Container>
      <HeaderComponent />
      {firstLoading && (
        <ContainerLoading>
          <WhisperSpinner size={50} backColor="#8b0304" />
        </ContainerLoading>
      )}
      <Body>
        <DataGrid
          className="grid"
          dataSource={companyTypes}
          onToolbarPreparing={onToolbarPreparing}
          onRowUpdating={options => {
            options.newData = { ...options.oldData, ...options.newData };
          }}
        >
          <HeaderFilter visible allowSearch />
          <Column dataField="Description" />
          <Column dataField="IsActive" width={130} dataType="boolean" />
          <Editing allowUpdating allowAdding />
        </DataGrid>
      </Body>
    </Container>
  );
};
