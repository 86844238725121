import React, { useCallback, useEffect, useState } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import {
  DataGrid,
  Column,
  HeaderFilter,
  Scrolling,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { FiExternalLink, FiPlus, FiRotateCcw, FiX } from 'react-icons/fi';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { WhisperSpinner } from 'react-spinners-kit';
import { useParams, useHistory } from 'react-router-dom';
import { TagBox } from 'devextreme-react';
import { Container, Body, ContainerLoading, ToolItem } from './styles';
import { HeaderComponent } from '../../components/Header';
import { useDocumentTitle } from '../../hooks/documentTitle';
import api, {
  Contract,
  ContractType,
  ContractTypeContentField,
} from '../../services/api';
import { SelectBox } from '../../components/SelectBox';
import { Button } from '../../components/Button';
import { FormGroup } from '../../components/FormGroup';
import { useAuth } from '../../hooks/auth';
import master from '../../services/master';

interface DataGridData {
  id: string;
  category: string;
  companies: string;
  isExtension: string;
  [name: string]: string;
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const DocumentsTypeGridView: React.FC<{ title?: string }> = ({
  title,
}) => {
  const history = useHistory();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const { setTitle } = useDocumentTitle();
  const params = useParams<{ id: string }>();
  const [idContractType, setIdContractType] = useState(params.id);
  const [contractTypeHighlightFields, setContractTypeHighlightFields] =
    useState<ContractTypeContentField[]>();
  const [documents, setDocuments] = useState<DataSource>(new DataSource([]));

  const [contractTypeSource, setContractTypeSource] = useState<CustomStore>();
  const [open, setOpen] = useState(false);
  const [fillRest, setFillRest] = useState(false);

  const [category, setCategory] = useState(0);
  const [categories, setCategories] = useState<DataSource>();
  const [companies, setCompanies] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );

  const [integrationGroupsSource, setIntegrationGroupsSource] =
    useState<DataSource>(new DataSource([]));
  const [integrationGroups, setIntegrationGroups] = useState<number[]>([]);

  const [contractType, setContractType] = useState(1);
  const [company, setCompany] = useState<number[]>([]);

  const loadContractTypes = useCallback(async () => {
    setContractTypeSource(
      new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get('/api/contract-types');
          return data;
        },
      }),
    );
  }, []);

  const loadCategories = useCallback(async () => {
    const categoriesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `api/type-categories/type/${contractType}`,
        );
        return data.filter((x: any) => x.isActive);
      },
    });

    setCategories(
      new DataSource({
        store: categoriesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [contractType]);

  const loadCompanies = useCallback(async () => {
    setCompanies({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const response = await master.get('/master/companies');
          return response.data;
        },
      }),
      paginate: true,
    });
  }, []);

  const loadIntegrationGroups = useCallback(async () => {
    const integrationGroupsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const response = await master.get(`/master/integration-groups`);
        return response.data;
      },
    });

    setIntegrationGroupsSource(
      new DataSource({
        store: integrationGroupsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  const loadDocuments = useCallback(async () => {
    const documentsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        setLoading(true);
        const [contracts, contractFields] = await Promise.all([
          api.get<Contract[]>(
            `/api/contracts/contract-types/${idContractType}`,
          ),
          api.get<ContractType>(`/api/contract-types/${idContractType}`),
        ]);

        setTitle(contractFields.data.description);
        setLoading(false);

        const highlightFields =
          contractFields.data.highlightFieldsObjectArray.filter(
            x => x.isHighlight,
          );

        setContractTypeHighlightFields(highlightFields);

        const data = contracts.data.map(x => {
          const newContract = {} as DataGridData;

          newContract.id = x.id.toString();
          newContract.companies = x.companiesObject
            .map(c => c.tradeName)
            .join(', ');
          newContract.isExtension = x.idParent ? 'Yes' : 'No';
          newContract.category = x.contractTypeCategory.description;

          highlightFields.map(field => {
            const value = x.contractContentField.find(
              f => f.idContentField === field.idContentField,
            );

            newContract[field.fieldName] = '';
            if (value) {
              newContract[field.fieldName] =
                value.fieldValue?.toString() ||
                value.fieldText ||
                (value.fieldDate !== undefined &&
                  format(parseISO(value.fieldDate), 'MMM dd yyyy')) ||
                (value.fieldBool === true ? 'Yes' : 'No');

              if (value.contentField.idFieldType === 1) {
                newContract[field.fieldName] = value.fieldText || '';
              }
            }
            if (!value && field.contentField.idFieldType === 3) {
              newContract[field.fieldName] = 'No';
            }
          });

          return newContract;
        });

        return data;
      },
    });

    setDocuments(
      new DataSource({
        store: documentsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [idContractType, setTitle]);

  useEffect(() => {
    loadDocuments();
    loadContractTypes();
    loadCategories();
    loadCompanies();
    loadIntegrationGroups();
  }, [
    setTitle,
    title,
    loadDocuments,
    loadContractTypes,
    loadCategories,
    loadCompanies,
    loadIntegrationGroups,
  ]);

  const openViewCell = useCallback(e => {
    return (
      <Tooltip title="Open Document" aria-label="open">
        <IconButton
          aria-label="Open Document"
          size="small"
          onClick={() => window.open(`/documents/${e.key}`, `Window${e.key}`)}
        >
          <FiExternalLink size={22} color="#8b0304" />
        </IconButton>
      </Tooltip>
    );
  }, []);

  const handlePostDocument = useCallback(async () => {
    if (
      contractType !== 0 &&
      company.length >= 1 &&
      category !== 0 &&
      integrationGroups.length >= 1
    ) {
      setFillRest(false);
      const createdContract = await api.post('/api/contracts', {
        idContractType: contractType,
        idCategory: category,
        companies: company,
        idContractStatus: 1,
        createdBy: user.userId,
        updatedBy: user.userId,
        createdAt: new Date(),
        updatedAt: new Date(),
        integrationGroups,
      });
      window.open(
        `/documents/${createdContract.data.id}`,
        `Window${createdContract.data.id}`,
      );
      setOpen(false);
      documents.reload();
    } else {
      setFillRest(true);
    }
  }, [
    category,
    company,
    contractType,
    documents,
    integrationGroups,
    user.userId,
  ]);

  return (
    <Container>
      <HeaderComponent hasTitle={false} />
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={50} backColor="#8b0304" />
        </ContainerLoading>
      )}
      <Body>
        <div className="categorySelect">
          <h1>Select a document type:</h1>
          <SelectBox
            className="select"
            valueExpr="id"
            displayExpr="description"
            dataSource={contractTypeSource}
            onChanged={id => {
              setIdContractType(id);
              history.push(`/documents/type/${id}`);
            }}
            value={parseInt(idContractType, 0)}
          />
        </div>
        {!!idContractType && (
          <div className="gridContainer">
            <div className="toolbar">
              <ToolItem onClick={() => documents.reload()}>
                <FiRotateCcw />
                Refresh
              </ToolItem>
              <ToolItem onClick={() => setOpen(true)}>
                <FiPlus />
                New Document
              </ToolItem>
            </div>
            <DataGrid className="grid" dataSource={documents} hoverStateEnabled>
              <Scrolling mode="infinite" />
              <HeaderFilter visible allowSearch />
              <Column
                caption="Third parties"
                dataField="companies"
                width={250}
              />
              {contractTypeHighlightFields?.map(field => (
                <Column
                  caption={field.fieldName}
                  key={field.id}
                  dataField={field.fieldName}
                />
              ))}
              <Column
                dataField="category"
                alignment="left"
                caption="Category"
              />
              <Column dataField="isExtension" width={150} />
              <Column
                type="buttons"
                cellRender={openViewCell}
                width={60}
                alignment="right"
              />
            </DataGrid>
          </div>
        )}
      </Body>

      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => {
          setOpen(false);
          setFillRest(false);
        }}
      >
        <DialogTitle>
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p>New Document</p>
            <FiX style={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
          </span>
        </DialogTitle>
        <DialogContent>
          <FormGroup fieldSetLabel="Document Type">
            <SelectBox
              onChanged={e => setContractType(parseInt(e, 0))}
              value={undefined}
              displayExpr="description"
              valueExpr="id"
              dataSource={contractTypeSource}
            />
          </FormGroup>
          <FormGroup fieldSetLabel="Category">
            <SelectBox
              value={undefined}
              displayExpr="description"
              valueExpr="id"
              onChanged={e => setCategory(parseInt(e, 0))}
              dataSource={categories}
              style={{ marginBottom: '15px' }}
            />
          </FormGroup>
          <FormGroup fieldSetLabel="Third-Party Company(ies)">
            <TagBox
              dataSource={companies}
              displayExpr="TradeName"
              valueExpr="ID"
              onValueChanged={e => setCompany(e.value)}
              stylingMode="outlined"
              searchEnabled
              searchExpr={['TradeName', 'CorporateName']}
            />
          </FormGroup>
          <FormGroup fieldSetLabel="Integration Company(ies)">
            <TagBox
              dataSource={integrationGroupsSource}
              displayExpr="Name"
              valueExpr="ID"
              onValueChanged={e => setIntegrationGroups(e.value)}
              stylingMode="outlined"
              searchEnabled
              searchExpr={['Name']}
            />
          </FormGroup>
          {fillRest && (
            <p style={{ color: 'red', fontWeight: 500 }}>
              Please fill in all the fields.
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setFillRest(false);
            }}
          >
            CANCEL
          </Button>
          <Button primary onClick={handlePostDocument}>
            CREATE
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
