import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  width: 100%;

  p {
    color: #DDDDDD;
    letter-spacing: .1em;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const HeaderLogo = styled.div`
  img {
    max-width: 100px;
  }
`;
