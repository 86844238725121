import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Body = styled.section`
  width: 100%;
  background-color: transparent;

  .actions {
    width: 95%;
    margin: 0 auto;
    margin-top: 10%;
    display: flex;
    gap: 15px;
    text-align: center;
    cursor: pointer;
    color: #bbbbbb;

    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 174px;

      .imgBox {
        background: #ffffffa6;
        padding: 35px;
        box-shadow: #909090 0px 0px 10px -2px;
        transition: 0.5s;

        h1 {
          text-transform: uppercase;
          font-weight: 700;
          margin-top: 20px;
        }
      }

      .imgBox:hover {
        box-shadow: #909090 0px 0px 15px -2px;
      }

      p {
        font-size: 12px;
        padding-top: 15px;
        font-weight: 700;
      }
    }
  }
`;
