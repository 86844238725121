import React, { useCallback, useEffect, useState } from 'react';

import CustomStore from 'devextreme/data/custom_store';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { FiX } from 'react-icons/fi';
import { TagBox } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import { Container, Body } from './styles';
import { HeaderComponent } from '../../components/Header';
import { SelectBox } from '../../components/SelectBox';
import { useDocumentTitle } from '../../hooks/documentTitle';
import api from '../../services/api';
import { FormGroup } from '../../components/FormGroup';
import { Button } from '../../components/Button';
import { useAuth } from '../../hooks/auth';
import master from '../../services/master';
import legalGroups from '../../config/legalGroups';

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const Documents: React.FC<{ title?: string }> = ({ title }) => {
  const { setTitle } = useDocumentTitle();
  const history = useHistory();
  const { user, hasScopes } = useAuth();
  const [category, setCategory] = useState(0);
  const [categories, setCategories] = useState<DataSource>();
  const [open, setOpen] = useState(false);
  const [fillRest, setFillRest] = useState(false);

  const [companies, setCompanies] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );
  const [contractTypeSource, setContractTypeSource] = useState<CustomStore>();

  const [integrationGroupsSource, setIntegrationGroupsSource] =
    useState<DataSource>(new DataSource([]));
  const [integrationGroups, setIntegrationGroups] = useState<number[]>([]);

  const [contractType, setContractType] = useState(1);
  const [company, setCompany] = useState<number[]>([]);

  const loadContractTypes = useCallback(async () => {
    setContractTypeSource(
      new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get('/api/contract-types');
          return data;
        },
      }),
    );
  }, []);

  const loadCategories = useCallback(async () => {
    const categoriesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `api/type-categories/type/${contractType}`,
        );
        return data.filter((x: any) => x.isActive);
      },
    });

    setCategories(
      new DataSource({
        store: categoriesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [contractType]);

  const loadCompanies = useCallback(async () => {
    setCompanies({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const response = await master.get('/master/companies');
          return response.data;
        },
      }),
      paginate: true,
    });
  }, []);

  const loadIntegrationGroups = useCallback(async () => {
    const integrationGroupsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const response = await master.get(`/master/integration-groups`);
        return response.data;
      },
    });

    setIntegrationGroupsSource(
      new DataSource({
        store: integrationGroupsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    setTitle(title);
    loadContractTypes();
    loadCategories();
    loadCompanies();
    loadIntegrationGroups();
  }, [
    setTitle,
    title,
    loadContractTypes,
    loadCategories,
    loadCompanies,
    loadIntegrationGroups,
  ]);

  const handlePostDocument = useCallback(async () => {
    if (
      contractType !== 0 &&
      company.length >= 1 &&
      category !== 0 &&
      integrationGroups.length >= 1
    ) {
      setFillRest(false);
      const createdContract = await api.post('/api/contracts', {
        idContractType: contractType,
        idCategory: category,
        companies: company,
        idContractStatus: 1,
        createdBy: user.userId,
        updatedBy: user.userId,
        createdAt: new Date(),
        updatedAt: new Date(),
        integrationGroups,
      });
      window.open(
        `/documents/${createdContract.data.id}`,
        `Window${createdContract.data.id}`,
      );
      setOpen(false);
    } else {
      setFillRest(true);
    }
  }, [category, company, contractType, integrationGroups, user.userId]);

  return (
    <Container>
      <HeaderComponent />
      <Body>
        <div className="categorySelect">
          <h1>Select a document type:</h1>
          <SelectBox
            className="select"
            valueExpr="id"
            displayExpr="description"
            dataSource={contractTypeSource}
            onChanged={e => history.push(`/documents/type/${e}`)}
            value={null}
          />
        </div>
        {hasScopes([legalGroups.Admin]) && (
          <Button
            primary
            onClick={() => setOpen(true)}
            className="newDocButton"
          >
            NEW DOCUMENT
          </Button>
        )}
      </Body>

      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => {
          setOpen(false);
          setFillRest(false);
        }}
      >
        <DialogTitle>
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p>New Document</p>
            <FiX style={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
          </span>
        </DialogTitle>
        <DialogContent>
          <FormGroup fieldSetLabel="Document Type">
            <SelectBox
              onChanged={e => setContractType(parseInt(e, 0))}
              value={undefined}
              displayExpr="description"
              valueExpr="id"
              dataSource={contractTypeSource}
            />
          </FormGroup>
          <FormGroup fieldSetLabel="Category">
            <SelectBox
              value={undefined}
              displayExpr="description"
              valueExpr="id"
              onChanged={e => setCategory(parseInt(e, 0))}
              dataSource={categories}
              style={{ marginBottom: '15px' }}
            />
          </FormGroup>
          <FormGroup fieldSetLabel="Third-Party Company(ies)">
            <TagBox
              dataSource={companies}
              displayExpr="TradeName"
              valueExpr="ID"
              onValueChanged={e => setCompany(e.value)}
              stylingMode="outlined"
              searchEnabled
              searchExpr={['TradeName', 'CorporateName']}
            />
          </FormGroup>
          <FormGroup fieldSetLabel="Integration Company(ies)">
            <TagBox
              dataSource={integrationGroupsSource}
              displayExpr="Name"
              valueExpr="ID"
              onValueChanged={e => setIntegrationGroups(e.value)}
              stylingMode="outlined"
              searchEnabled
              searchExpr={['Name']}
            />
          </FormGroup>
          {fillRest && (
            <p style={{ color: 'red', fontWeight: 500 }}>
              Please fill in all the fields.
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setFillRest(false);
            }}
          >
            CANCEL
          </Button>
          <Button primary onClick={handlePostDocument}>
            CREATE
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
