import React, { useCallback, useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
} from '@material-ui/core';

import { TextBox } from 'devextreme-react/text-box';
import { CheckBox } from 'devextreme-react/check-box';

import { WhisperSpinner } from 'react-spinners-kit';

import { DataGrid } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import {
  Column,
  Editing,
  Lookup,
  RowDragging,
  HeaderFilter,
  Scrolling,
} from 'devextreme-react/data-grid';
import { Button } from '../../../../components/Button';
import { FormGroup } from '../../../../components/FormGroup';

import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

interface DialogCustomProps extends DialogProps {
  contractTypeId: number;
  handleClose(): void;
  contractTypes: DataSource;
}

export const DialogEditContractType: React.FC<DialogCustomProps> = ({
  contractTypeId,
  open,
  handleClose,
  contractTypes,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [fillRest, setFillRest] = useState(false);
  const [name, setName] = useState('');
  const [defaultFolders, setDefaultFolders] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [hasAlert, setHasAlert] = useState(false);
  const [daysToAlert, setDaysToAlert] = useState('');

  const [contentFieldsSource, setContentFieldsSource] = useState<CustomStore>();
  const [contractTypeContentFieldsStore, setContractTypeContentFieldsStore] =
    useState<CustomStore>();
  const [contractTypeContentFieldsSource, setContractTypeContentFieldsSource] =
    useState<DataSource>();

  const loadContractTypeInfo = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`api/contract-types/${contractTypeId}`);
    setName(data.description);
    setDefaultFolders(data.defaultFolders);
    setIsActive(data.isActive);
    setHasAlert(data.hasAlert);
    setDaysToAlert(data.daysToAlert);

    setLoading(false);
  }, [contractTypeId]);

  const loadContentFields = useCallback(async () => {
    setContentFieldsSource(
      new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get('/api/content-fields');
          data.map((contentField: any) => {
            if (!contentField.isActive) {
              contentField.fieldName += ' (Inactive)';
            }
          });
          return data;
        },
      }),
    );
  }, []);

  const loadContractTypeContentFields = useCallback(async () => {
    const contractTypeContentFields = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/contract-type-content-fields/type/${contractTypeId}`,
        );
        return data;
      },
      insert: async data => {
        try {
          await api.post(`/api/contract-type-content-fields`, {
            ...data,
            idContractType: contractTypeId,
          });
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        } catch (error: any) {
          if (error && error.response && error.response.data) {
            if (
              error.response.data.includes(
                'This Content Field is already assigned to this Contract Type.',
              )
            ) {
              addToast({
                title: '',
                type: 'error',
                description:
                  'This Content Field is already assigned to this Contract Type.',
              });
            }
          }
        }
      },
      update: async (id, data) => {
        await api.put(`/api/contract-type-content-fields/${id}`, data);
      },
      remove: async id => {
        await api.delete(`/api/contract-type-content-fields/${id}`);
      },
    });

    setContractTypeContentFieldsStore(contractTypeContentFields);

    setContractTypeContentFieldsSource(
      new DataSource({
        store: contractTypeContentFields,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [addToast, contractTypeId]);

  useEffect(() => {
    loadContractTypeInfo();
    loadContentFields();
    loadContractTypeContentFields();
  }, [loadContractTypeInfo, loadContentFields, loadContractTypeContentFields]);

  const handleEditContractType = useCallback(async () => {
    if (name !== '' && defaultFolders !== '') {
      await api.put(`/api/contract-types/${contractTypeId}`, {
        description: name,
        isActive,
        defaultFolders,
        hasAlert,
        daysToAlert,
      });
      contractTypes.reload();
      handleClose();
      addToast({
        title: 'Document type edited successfully!',
        type: 'success',
      });
    } else {
      setFillRest(true);
    }
  }, [
    name,
    defaultFolders,
    contractTypeId,
    isActive,
    hasAlert,
    daysToAlert,
    contractTypes,
    handleClose,
    addToast,
  ]);

  const handleDrag = useCallback(
    async e => {
      await contractTypeContentFieldsStore?.update(e.itemData.id, {
        ...e.itemData,
        order: e.toIndex + 1,
      });
      await e.component.refresh();
    },
    [contractTypeContentFieldsStore],
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        setFillRest(false);
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">Edit Document Type</DialogTitle>
      <DialogContent>
        {!loading ? (
          <>
            <FormGroup fieldSetLabel="Name">
              <TextBox
                value={name}
                onValueChange={e => setName(e)}
                stylingMode="outlined"
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Has Alerts?">
              <CheckBox
                onValueChange={(value: boolean) => setHasAlert(value)}
                value={hasAlert}
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Days to alert">
              <TextBox
                value={daysToAlert}
                onValueChange={e => setDaysToAlert(e)}
                stylingMode="outlined"
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Default Folders">
              <p
                style={{
                  color: 'darkgrey',
                  fontWeight: 500,
                  marginBottom: '10px',
                }}
              >
                The default folders should be separated by commas and without
                spaces in between them, exactly as shown in the following
                example: addendum,policies,nda
              </p>
              <TextBox
                value={defaultFolders}
                onValueChange={e => setDefaultFolders(e)}
                stylingMode="outlined"
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Is Active">
              <CheckBox
                onValueChange={(value: boolean) => setIsActive(value)}
                value={isActive}
              />
            </FormGroup>

            <DataGrid
              dataSource={contractTypeContentFieldsSource}
              onRowUpdating={options => {
                options.newData = { ...options.oldData, ...options.newData };
              }}
            >
              <RowDragging
                allowReordering
                onReorder={handleDrag}
                showDragIcons
                dropFeedbackMode="push"
              />
              <Scrolling mode="infinite" />
              <Editing allowAdding allowUpdating allowDeleting mode="cell" />
              <HeaderFilter visible allowSearch />
              <Column dataField="idContentField" caption="Content Field">
                <Lookup
                  displayExpr="fieldName"
                  valueExpr="id"
                  dataSource={contentFieldsSource}
                />
              </Column>
              <Column
                dataField="isHighlight"
                dataType="boolean"
                caption="Is Highlighted"
              />
              <Column dataField="isShared" dataType="boolean" />
              <Column dataField="isAlertDate" dataType="boolean" />
              <Column dataField="isAlertNotice" dataType="boolean" />
              <Column dataField="isAlertAutoRenovation" dataType="boolean" />
              <Column
                dataField="isAlertUndeterminedPeriod"
                dataType="boolean"
              />
            </DataGrid>

            {fillRest && (
              <p style={{ color: 'red', fontWeight: 500 }}>
                Please fill in all the fields.
              </p>
            )}
          </>
        ) : (
          <WhisperSpinner size={24} backColor="#8b0304" frontColor="#fff" />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setFillRest(false);
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button onClick={handleEditContractType} primary loading={loading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
