import React, { useState, useCallback, useEffect } from 'react';

import NumberFormat, { NumberFormatPropsBase } from 'react-number-format';

import { Container } from './styles';

interface MoneyBoxProps extends NumberFormatPropsBase {
  onChanged?(value: number | undefined): void;
  value?: number;
}

export const MoneyBox: React.FC<MoneyBoxProps> = ({
  value: defaultValue,
  onChanged,
  readOnly: _readOnly,
  ...rest
}) => {
  const [readOnly, setReadOnly] = useState<boolean | undefined>(false);
  const [value, setValue] = useState<number | undefined>(0);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    setReadOnly(_readOnly);
  }, [_readOnly]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = useCallback(
    (v: number | undefined) => {
      const newValue = v;

      setValue(newValue);

      if (newValue === value) return;

      if (typingTimeout) clearTimeout(typingTimeout);

      if (!readOnly && onChanged) {
        setTypingTimeout(
          setTimeout(() => {
            onChanged(newValue);
          }, 3000),
        );
      }
    },
    [onChanged, readOnly, typingTimeout, value],
  );

  // const handleBlur = useCallback(
  //   (v: number | undefined) => {
  //     const newValue = v;

  //     if (newValue === value) return;

  //     if (typingTimeout) clearTimeout(typingTimeout);

  //     if (!readOnly && onChanged) {
  //       onChanged(newValue);
  //     }
  //   },
  //   [onChanged, readOnly, typingTimeout, value],
  // );

  return (
    <Container>
      <NumberFormat
        onValueChange={e => handleChange(e.floatValue)}
        value={value}
        thousandSeparator="."
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        fixedDecimalScale
        readOnly={readOnly}
        {...rest}
      />
    </Container>
  );
};
