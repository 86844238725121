import React, { useCallback, useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
} from '@material-ui/core';

import { TextBox } from 'devextreme-react/text-box';

import { CheckBox, SelectBox } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { WhisperSpinner } from 'react-spinners-kit';
import { Button } from '../../../../components/Button';
import { FormGroup } from '../../../../components/FormGroup';

import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

interface DialogCustomProps extends DialogProps {
  handleClose(): void;
  categories: DataSource;
  categoryId: number;
}

export const DialogEditCategory: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  categories,
  categoryId,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [fillRest, setFillRest] = useState(false);
  const [name, setName] = useState('');
  const [contractType, setContractType] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [contractTypeSource, setContractTypeSource] = useState<DataSource>();

  const loadCategoryInfo = useCallback(async () => {
    setLoading(true);
    const response = await api.get(`/api/type-categories/${categoryId}`);
    setName(response.data.description);
    setContractType(response.data.idContractType);
    setIsActive(response.data.isActive);
    setLoading(false);
  }, [categoryId]);

  const loadContractTypes = useCallback(async () => {
    const contractTypesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/contract-types');
        return data;
      },
    });

    setContractTypeSource(
      new DataSource({
        store: contractTypesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadCategoryInfo();
    loadContractTypes();
  }, [loadCategoryInfo, loadContractTypes]);

  const handlePutCategory = useCallback(async () => {
    if (name !== '' && contractType !== 0) {
      await api.put(`/api/type-categories/${categoryId}`, {
        description: name,
        idContractType: contractType,
        isActive,
      });
      categories.reload();
      handleClose();
      addToast({
        title: 'Category edited successfully!',
        type: 'success',
      });
    } else {
      setFillRest(true);
    }
  }, [
    addToast,
    categories,
    categoryId,
    contractType,
    handleClose,
    isActive,
    name,
  ]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setFillRest(false);
        handleClose();
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Edit Category</DialogTitle>
      <DialogContent>
        {loading ? (
          <WhisperSpinner size={24} backColor="#8b0304" frontColor="#fff" />
        ) : (
          <>
            <FormGroup fieldSetLabel="Name">
              <TextBox
                onValueChange={e => setName(e)}
                value={name}
                stylingMode="outlined"
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Document Type">
              <SelectBox
                dataSource={contractTypeSource}
                value={contractType}
                displayExpr="description"
                valueExpr="id"
                onValueChanged={e => setContractType(e.value)}
                stylingMode="outlined"
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Is Active">
              <CheckBox
                onValueChange={(value: boolean) => setIsActive(value)}
                value={isActive}
              />
            </FormGroup>

            {fillRest && (
              <p style={{ color: 'red', fontWeight: 500 }}>
                Please fill in all the fields.
              </p>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setFillRest(false);
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button onClick={handlePutCategory} primary>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
