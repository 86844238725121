import React, { useCallback, useEffect, useState } from 'react';

import {
  DataGrid,
  Column,
  Editing,
  Lookup,
  HeaderFilter,
  Scrolling,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { WhisperSpinner } from 'react-spinners-kit';
import { Container, Body, ContainerLoading } from './styles';
import { HeaderComponent } from '../../components/Header';
import { useDocumentTitle } from '../../hooks/documentTitle';
import master from '../../services/master';
import { useAuth } from '../../hooks/auth';
import legalGroups from '../../config/legalGroups';

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const Companies: React.FC<{ title?: string }> = ({ title }) => {
  const { hasScopes } = useAuth();
  const { setTitle } = useDocumentTitle();
  const [firstLoading, setFirstLoading] = useState(true);
  const [companies, setCompanies] = useState<DataSource>();
  const [clients, setClients] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );
  const [companyTypes, setCompanyTypes] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );

  const loadCompanies = useCallback(async () => {
    const companiesStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/companies');
        setFirstLoading(false);
        return data;
      },
      insert: async data => {
        await master.post('/master/companies', data);
      },
      update: async (id, data) => {
        await master.put(`/master/companies/${id}`, {
          CorporateName: data.CorporateName,
          IDClient: data.IDClient,
          IDCompanyType: data.IDCompanyType,
          IsActive: data.IsActive,
          TradeName: data.TradeName,
        });
      },
    });

    setCompanies(
      new DataSource({
        store: companiesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  const loadClients = useCallback(async () => {
    setClients({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const response = await master.get('/master/clients');
          response.data.map((client: any) => {
            if (!client.IsActive) {
              client.Name += ' (Inactive)';
            }
          });
          return response.data;
        },
      }),
      paginate: true,
    });
  }, []);

  const loadCompanyTypes = useCallback(async () => {
    setCompanyTypes({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const response = await master.get('/master/company-types');
          return response.data;
        },
      }),
      paginate: true,
    });
  }, []);

  useEffect(() => {
    setTitle(title);
    loadCompanies();
    loadClients();
    loadCompanyTypes();
  }, [setTitle, title, loadCompanies, loadClients, loadCompanyTypes]);

  const onToolbarPreparing = useCallback(e => {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach((item: any) => {
      if (item.name === 'addRowButton') {
        item.showText = 'always';
        item.options = {
          ...item.options,
          text: 'NEW THIRD-PARTY COMPANY',
        };
      }
    });
  }, []);

  return (
    <Container>
      <HeaderComponent />
      {firstLoading && (
        <ContainerLoading>
          <WhisperSpinner size={50} backColor="#8b0304" />
        </ContainerLoading>
      )}
      <Body>
        <DataGrid
          visible={!firstLoading}
          className="grid"
          dataSource={companies}
          onToolbarPreparing={onToolbarPreparing}
          onRowUpdating={options => {
            options.newData = { ...options.oldData, ...options.newData };
          }}
        >
          <HeaderFilter visible allowSearch />
          <Scrolling mode="infinite" />
          <Column dataField="CorporateName" caption="Legal Name" />
          <Column dataField="TradeName" caption="Name" />
          <Column dataField="IDClient" caption="Client">
            <Lookup
              dataSource={clients}
              displayExpr="Name"
              valueExpr="ID"
              allowClearing
            />
          </Column>
          <Column dataField="IDCompanyType" caption="Company Type">
            <Lookup
              displayExpr="Description"
              valueExpr="ID"
              dataSource={companyTypes}
              allowClearing
            />
          </Column>
          <Column dataField="CreatedAt" dataType="date" format="dd/MM/yyyy" />
          <Column dataField="IsActive" width={120} />
          <Editing
            allowUpdating={hasScopes([legalGroups.Admin])}
            allowAdding={hasScopes([legalGroups.Admin])}
          />
        </DataGrid>
      </Body>
    </Container>
  );
};
